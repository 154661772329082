<template>
    <div class="content">
        <img class="headjil" @click="exchange" src="https://ppyos.xijiuyou.com/2401/mj-exchange-records.png" alt="">
        <div class="headtitle" v-if="myPools.length>0">
            <img src="https://ppyos.xijiuyou.com/202301/sd-left-icon.png" alt="">
            <img src="https://ppyos.xijiuyou.com/202301/exchange-txt-img.png" alt="">
            <img src="https://ppyos.xijiuyou.com/202301/sd-right-icon.png" alt="">
        </div>
        <div  v-for="(item,index) in myPools" :key="index">
            <div :class="_item.showType!==3?'list':'listtop'" v-for="(_item,_index) in item" :key="_index">
              <div v-if="_item.showType!==3"><p>{{ _item.desc }}</p><img @click="exchangebrand(_item)" src="https://ppyos.xijiuyou.com/202301/exchange-btn.png" alt=""></div>
              <template  v-if="_item.usingImages.length === 1 && _item.showType!==3">
                <img v-for="(item_,index_) in _item.usingImages" :key="index_" :src="item_" alt="" />
              </template>
              <div  v-if="_item.usingImages.length !== 1 && _item.showType!==3">
                  <img v-for="(item_,index_) in _item.usingImages" :key="index_" :src="item_" alt="">
              </div>
              <div v-if="_item.showType===3">
                  <div><p>{{ _item.desc }}</p><img  @click="exchangebrand(_item)" src="https://ppyos.xijiuyou.com/202301/exchange-btn.png" alt=""></div>
                  <img v-for="(item_,index_) in _item.usingImages" :key="index_" :src="item_" alt="" />
              </div>
            </div>
        </div>
        <div class="headtitle">
            <img src="https://ppyos.xijiuyou.com/202301/sd-left-icon.png" alt="">
            <img class="imgs" src="https://ppyos.xijiuyou.com/2401/mj-card-img.png" alt="">
            <span>({{note}})</span>
            <img src="https://ppyos.xijiuyou.com/202301/sd-right-icon.png" alt="">
        </div>

        <div class="mylist">
            <div v-for="(item,index) in myOwnCards" :key="index" :style="item.count===0?'opacity: 0.35;':''" @click="cardClick(item)">
                <img :src="item.img" alt="">
                <p v-if="item.count!==0">{{ item.count }}</p>
            </div>
        </div>
        <xwpopup :isshowpopup="guaj" @close="closephones()" :hiddenClose="false">
            <div class="container" id="top">
              <img class="lefty" v-if="(indexs+1) >1" @click="goout(2)" src="https://ppyos.xijiuyou.com/202301/left-yellow-img.png" alt="">
                <div class="award_box">
                    <img class="maj" :src="myLuckCards.length>0?myLuckCards[indexs].img:''" alt="">
                    <img class="smallbanner" src="https://ppyos.xijiuyou.com/202301/red-small-banner.png" alt="">
                    <p class="award_mj">{{indexs+1}} / {{ myLuckCards.length }}</p>
                    <div class="award" v-if="showPrize" :style="!shave?awardText.isAward?'':'background:#ffffff;':showPrize&& myLuckCards[indexs].isAward?'':'background:#ffffff;'">
                        <div class="title" v-if="shave" :style="showPrize&& myLuckCards[indexs].isAward?'font-size: 14px;':''">
                          {{showPrize&& myLuckCards[indexs].isAward?'恭喜刮到':myLuckCards[indexs].awardText}}
                          <span v-if="showPrize && myLuckCards[indexs].isAward">{{myLuckCards[indexs].awardText}}元</span>
                        </div>
                        <div class="title" v-else  :style="awardText.isAward?'font-size: 14px;':''">
                          {{ awardText.isAward?'恭喜刮到':awardText.awardText}}
                          <span v-if="awardText.isAward">{{awardText.awardText}}元</span>
                        </div>
                    </div>
                    <img class="canvas" v-show="!isScratch" @click="touchstart" src="https://ppyos.xijiuyou.com/202301/gg-card.png" alt=""/>
                    <!-- <canvas
                        id="c1"
                        class="canvas"
                        @touchmove="touchmove"
                        @touchstart="touchstart"
                        @touchend="touchend"
                        v-show="!isScratch"
                    ></canvas> -->
                </div>
                <img class="leftys" v-if="myLuckCards.length !== (indexs+1)" @click="goout(1)" src="https://ppyos.xijiuyou.com/202301/right-yellow-img.png" alt="">
            </div>
        </xwpopup>

        <xwpopup :isshowpopup="newpeople" @close="closenewpeople()" :hiddenClose="false">
            <div class="newpeople">
                <img src="https://ppyos.xijiuyou.com/2401/mj-con-img.png" alt="">
                <p>兑换成功，获得 <span>{{brandcontent.award}}元</span><br/> 已发放至您的彩贝账户</p>
                <img @click="closenewpeople()" src="https://ppyos.xijiuyou.com/202301/sx-btn.png" alt="">
            </div>
        </xwpopup>
        <xwpopup :isshowpopup="Tips" @close="closeTips()" :hiddenClose="false">
            <div class="Tips">
                <div>提示</div>
                <div>是否确定兑换 <span>“{{brandcontent.desc}}({{brandcontent.award}}元)”</span> ？兑换后该牌将收回（只能兑换一次）</div>
                <div>
                    <p @click="closeTips()">取消</p>
                    <p @click="quanpaix">确认</p>
                </div>
            </div>
        </xwpopup>
    </div>
  </template>
  <script>
  import { Toast } from "vant";
import brand from "../../api/brand";
import xwpopup from "../../components/Popupbox.vue";
  export default {
    components: {
    xwpopup,
  },
  data() {
    return {
      c1: "", //画布
      ctx: "", //画笔
      ismousedown: false, //标志用户是否按下鼠标或开始触摸
      fontem: "", // 获取html字体大小
      isScratch: false, // 是否刮过卡
      showPrize: false ,// 显示奖品
      guaj:false,
      newpeople:false,
      Tips:false,
      myOwnCards:[],
      myPools:[],
      brandcontent:{},
      myLuckCards:[],
      indexs:0,
      shave:true,
      awardText:{},
      note:"",
    };
  },
  mounted() {
    this.fontem = parseInt(
      window.getComputedStyle(document.documentElement, null)["font-size"]
    );
    this.myCards();
    //这是为了不同分辨率上配合@media自动调节刮的宽度
    
  },
  methods: {
    goout(index){
      const tome = setTimeout(()=>{
        // this.c1 = document.getElementById("c1");
        // this.c1.width = 205;
        // this.c1.height = 78;
        // this.ctx = this.c1.getContext("2d");
        // this.initCanvas();
        clearTimeout(tome)
      },200)
      this.shave = true;
      if(index === 1){
        this.indexs++;
        this.isScratch = this.myLuckCards[this.indexs].status ===0 ?false:true;
        this.showPrize = this.myLuckCards[this.indexs].status ===0 ?false:true;
      }else{
        this.indexs--;
        this.isScratch = this.myLuckCards[this.indexs].status ===0 ?false:true;
        this.showPrize = this.myLuckCards[this.indexs].status ===0 ?false:true;
      }
    },
    cardClick(item){
      if(item.count === 0){
        Toast("这个麻将您没有抽中过哦");
        return false;
      }
      this.indexs = 0;
      this.guaj = true;
      brand.cardClick(item.type).then(res=>{
        this.myLuckCards = res.data.myLuckCards;
        this.isScratch = res.data.myLuckCards[0].status ===0 ?false:true;
        this.showPrize = res.data.myLuckCards[0].status ===0 ?false:true;
      })
      // const tome = setTimeout(()=>{
      //   this.c1 = document.getElementById("c1");
      //   this.c1.width = 205;
      //   this.c1.height = 78;
      //   this.ctx = this.c1.getContext("2d");
      //   this.initCanvas();
      //   clearTimeout(tome)
      // },200)
    },
    exchangebrand(item){
      this.brandcontent = item;
      this.Tips = true;
      
    },
    myCards(){
      brand.myCards().then(res=>{
        this.myPools = res.data.myPools;
        this.myOwnCards = res.data.myOwnCards;
        this.note = res.data.note;
      })
    },
    quanpaix(){
        this.Tips = false;
        brand.luckyCardexchange(this.brandcontent.drawInfoIds).then(()=>{
          this.newpeople = true;
          
        })
    },
    closeTips(){
        this.Tips = !this.Tips;
    },
    closenewpeople(){
        this.newpeople = !this.newpeople;
        this.myCards();
    },
    exchange(){
        this.$router.push({
            name:"brandexchange",
        })
    },
    closephones(){
        this.guaj = !this.guaj;
        this.shave = true;
    },
    // 画刮刮卡
    // initCanvas() {
    //   this.ctx.globalCompositeOperation = "source-over";
    //   // var image = new Image();
    //   // image.crossOrigin = "anonymous";
    //   // image.src = "https://ppyos.xijiuyou.com/202301/gg-card.png";
    //   // this.ctx.drawImage(image, 0,0,205,78);
    //   this.ctx.fillStyle = "#e5e5e5";
    //   this.ctx.fillRect(0, 0, this.c1.clientWidth, this.c1.clientHeight);
    //   this.ctx.fill();
    //   this.ctx.font = "Bold 16px Arial";
    //   this.ctx.textAlign = "center";
    //   this.ctx.fillStyle = "#a0a0a0";
    //   this.ctx.fillText("刮一刮", this.c1.width / 2, 47);
    //   //有些老的手机自带浏览器不支持destination-out,下面的代码中有修复的方法
    //   this.ctx.globalCompositeOperation = "destination-out";
    // },
    touchstart(e) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      if(this.shave){
        this.shave = false;
        brand.cardLotty(this.myLuckCards[this.indexs].id).then(res=>{
          Toast.clear();
          this.awardText = res.data;
          this.myLuckCards[this.indexs].awardText = res.data.awardText;
          this.myLuckCards[this.indexs].isAward = res.data.isAward;
          this.myLuckCards[this.indexs].status = 1;
          this.isScratch = true;
          this.showPrize = true
        })
      }
      e.preventDefault();
      this.ismousedown = true;
    },
    // 操作刮卡
    touchend(e) {
      sessionStorage.setItem('isScratch',true)
      e.preventDefault();
      //得到canvas的全部数据
      var a = this.ctx.getImageData(0, 0, 205, 78);
      var j = 0;
      for (var i = 3; i < a.data.length; i += 4) {
        if (a.data[i] == 0) j++;
      }
      //当被刮开的区域等于一半时，则可以开始处理结果
      if (j >= a.data.length / 8) {
        this.isScratch = true;
      }
      this.ismousedown = false;
    },
    // touchmove(e) {
    //   this.showPrize = true
    //   e.preventDefault();
    //   if (this.ismousedown) {
    //     if (e.changedTouches) {
    //       e = e.changedTouches[e.changedTouches.length - 1];
    //     }
    //     var topY = document.getElementById("top").offsetTop;
    //     var oX = this.c1.offsetLeft,
    //       oY = this.c1.offsetTop + topY;
    //     var x = (e.clientX + document.body.scrollLeft || e.pageX) - oX || 0,
    //       y = (e.clientY + document.body.scrollTop || e.pageY) - oY || 0;
    //     //画360度的弧线，就是一个圆，因为设置了ctx.globalCompositeOperation = 'destination-out';
    //     //画出来是透明的
    //     this.ctx.beginPath();
    //     this.ctx.arc(x, y, this.fontem * 0.5, 0, Math.PI * 2, true); // 调整画笔的大小
    //     //下面3行代码是为了修复部分手机浏览器不支持destination-out
    //     //我也不是很清楚这样做的原理是什么
    //     this.c1.style.display = 'none';
    //     this.c1.offsetHeight;
    //     this.c1.style.display = 'inherit';
    //     this.ctx.fill();
    //   }
    // }
  }
  };
  </script>
  <style lang="less" scoped>
  .container {
  width: 100%;
  padding:180px 0 0;
  position: relative;
  .lefty{
    width: 29px;
    position: absolute;
    left: 0;
    top: 200px;
  }
  .leftys{
    width: 29px;
    position: absolute;
    right: 0;
    top: 200px;
  }
  .award_box {
    height: 252px;
    width: 263px;
    margin: 0px auto;
    background: url("https://ppyos.xijiuyou.com/2401/mj-gift-big-img.png") no-repeat;
    background-size: 100%;
    padding-top: 1px;
    .award_mj{
      position: absolute;
      right: 65px;
      top: 65px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFF4D2;
    }
    .title{
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFF4D2;
    }
    .smallbanner{
      display: block;
      width: 143px;
      margin: -4px auto 7px;
    }
    .maj{
      display: block;
      width: 78px;
      margin: -30px auto 0;
      position: relative;
      z-index: 1;
    }
    .award {
      width: 205px;
      height: 78px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("https://ppyos.xijiuyou.com/202301/gg-card-result.png") no-repeat;
      background-size: 100%;
      border-radius: 15px;
      margin-top: 42px;
      .title {
        color: rgba(3, 45, 97, 1);
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #444444;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        padding: 0 10px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        span {
          color: #FF3500;
        }
      }
    }
    .canvas {
      position: relative;
      width: 205px;
      height: 78px;
      border-radius: 15px;
      margin-top: 42px;
      z-index: 10;
    }
  }
  }
  .content{
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(180deg, #FF4431 0%, #FFCF7C 100%);
    text-align: center;
    .headjil{
        width: 115px;
    }
    .headtitle{
        margin-top: 17px;
        margin-bottom: 10px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFF6D4;
        display: flex;
        justify-content: center;
        img{
            width: 14px;
        }
        img:nth-child(2){
            width: 94px;
            height: 20px;
            margin: 0 6px;
        }
        .imgs:nth-child(2){
            width: 112px;
        }
    }
    .list{
        width: calc(100% - 16px);
        background: rgba(255, 255, 255, 0.25);
        border-radius: 10px;
        padding:9px 11px 12px;
        margin: 0 auto 8px;
        div{
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFF6D4;
            align-items: center;
            margin-bottom: 11px;
            img{
                width: 66px;
            }
        }
        div:nth-child(2){
            justify-content: center;
            img{
                width: 58px;
                margin: 0 5px;
            }
        }
        img{
            width: calc(100% - 38px);
        }
    }
    .listtop{
        display: flex;
        justify-content: space-between;
        width: calc(100% - 16px);
        margin: 0 auto 8px;
        div{
            width: calc(50% - 5px);
            background: rgba(255, 255, 255, 0.25);
            border-radius: 10px;
            padding: 9px 8px 13px;
            div:nth-child(1){
                padding: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFF6D4;
                background:none;
                margin-bottom: 11px;
                img{
                    width: 66px;
                }
            }
            img{
                width: 58px;
            }
        }
        
    }
  }
  .mylist{
    width: calc(100% - 14px);
    display: flex;
    flex-wrap: wrap;
    margin: 24px auto 0;
    div{
        width: calc(25% - 12px);
        margin: 0 6px 14px;
        position: relative;
        img{
            width: 100%;
            box-shadow: 0px 4px 10px 0px rgba(202,0,0,0.9), inset 0px -1px 9px 0px rgba(255,5,5,0.5);
            border-radius: 10px;
        }
        p{
            position: absolute;
            width: 22px;
            height: 22px;
            background: linear-gradient(180deg, #FF6A42 0%, #FF3E00 100%);
            border: 2px solid rgba(255, 224, 169, 1);
            border-radius: 50%;
            font-size: 15px;
            font-family: DINOffcPro-Bold, DINOffcPro;
            font-weight: bold;
            color: #FFE6BB;
            line-height: 16px;
            text-align: center;
            right: -8px;
            top: -8px;
            padding-top: 2.5px;
        }
    }
  }
  .newpeople{
  width: calc(100% - 100px);
  background: #FFFFFF;
  border-radius: 20px;
  margin-top: 150px;
  padding-bottom: 30px;
  img:nth-child(1){
    width: 100%;
    margin-top: -57px;
  }
  p{
    margin-top: 15px;
    margin-bottom: 25px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    span{
      font-size: 24px;
      color: rgba(255, 53, 0, 1);
    }
  }
  img:nth-child(3){
    width: 151px;
  }
}
.Tips{
    width: calc(100% - 100px);
    margin-top: 150px;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 24px 15px 30px;
    div:nth-child(1){
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #444444;
        margin-bottom: 20px;
    }
    div:nth-child(2){
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #444444;
        span{
            color: rgba(255, 53, 0, 1);
        }
    }
    div:nth-child(3){
        display: flex;
        justify-content: center;
        margin-top: 30px;
        p{
            margin: 0 7px;
            width: 110px;
            height: 44px;
            background: #EEEEEE;
            border-radius: 27px;
            text-align: center;
            line-height: 44px;
        }
        p:nth-child(2){
            background: linear-gradient(180deg, #FF8B45 0%, #FF1A1A 100%);
            box-shadow: inset 0px 4px 6px 0px rgba(255,215,89,0.5), inset 0px 1px 3px 0px rgba(255,0,55,0.5);
            color: #ffffff;
        }
    }
}
  </style>